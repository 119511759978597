import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Card, CardBody, CardTitle, CardText,
    Col, Container, Row,
    Input, Badge, Alert,
} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencilAlt, faFileUpload, faPlus, faX} from '@fortawesome/free-solid-svg-icons';
import Navbar from "../molecules/NavBar";
import QuoteTable from "../molecules/tables/QuoteTable.js";
import LoadingSpinner from "../atoms/loading/LoadingSpinner.js";
import withRouter from "../../helper/withRouter.js";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import AddressCard from "../organism/AddressCard.js";
import AddressObject from "@kjdelectronics/ps-quotetool-domain/obj/Address.js";
import AddressSection from "../molecules/AddressSection.js";
import AccountContactsSection from "../molecules/AccountContactsSection.js";
import AccountPageHeading from "../atoms/AccountPageHeading.js";
import CustomerShippingAccountsSection from "../molecules/CustomerShippingAccountSection.js";
import DocumentUploader from "../molecules/DocumentUploader.js";
import ValidationErrorList from "@kjdelectronics/ps-quotetool-domain/obj/error/ValidationErrorList.js";
import {getUrlSegmentFromCompanyId} from "@kjdelectronics/ps-quotetool-domain/obj/saturn/SaturnCompanyUtils";
import TaxIdsAccountSection from "../molecules/TaxIdsAccountSection.js";

class AccountPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            account: null,
            isEditingNotes: false,
            editingNotes: props.account?.notes
        };

        this.handleTaxGroupChange = this.handleTaxGroupChange.bind(this);
        this.handleTaxableChange = this.handleTaxableChange.bind(this);
        this.handleNotesBlur = this.handleNotesBlur.bind(this);
        this.handleAccountNameChange = this.handleAccountNameChange.bind(this);
        this.handlePaymentTermsChange = this.handlePaymentTermsChange.bind(this);
    }

    handleTaxGroupChange = async (taxGroup) => {
        const newAccount = await this.props.quoteToolClient.patchAccount({
            accountId: this.state.account.id,
            patch: { "taxes.taxGroup": taxGroup }
        });
        this.setState({ account: newAccount });
    };

    handleTaxableChange = async (isTaxable) => {
        const newAccount = await this.props.quoteToolClient.patchAccount({
            accountId: this.state.account.id,
            patch: { "taxes.isTaxable": isTaxable }
        });
        this.setState({ account: newAccount });
    };

    handlePaymentTermsChange = async (paymentTermId) => {
        const newAccount = await this.props.quoteToolClient.patchAccount({
            accountId: this.state.account.id,
            patch: {
                meta: {
                    paymentTermId
                }
            }
        });
        this.setState({ account: newAccount });
    };

    handleNotesBlur = async () => {
        const account = this.state.account;
        if (this.state.editingNotes !== account.notes) {
            const newAccount = await this.props.quoteToolClient.patchAccount({
                accountId: account.id,
                patch: { notes: this.state.editingNotes }
            });
            this.setState({ isEditingNotes: false, account: newAccount });
        }
        else {
            this.setState({ isEditingNotes: false });
        }
    };

    componentDidMount() {
        this.getAccount();
    }

    async getAccount() {
        const account = await this.props.quoteToolClient.getAccountByIdOrReference(this.props?.router?.params?.accountId);
        this.setState({ account, editingNotes: account.notes });
        if (this.state.account === null)
            setTimeout(this.scrollToHash, 300);
    }

    scrollToHash() {
        const { hash } = window.location;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

    handleAccountNameChange = async (newName) => {
        const { account } = this.state;
        const updatedAccount = await this.props.quoteToolClient.patchAccount({
            accountId: account.id,
            patch: { name: newName }
        });
        this.setState({ account: updatedAccount });
        toast.success('Account name updated!');
    };

    handleNotesBlur = async () => {
        const account = this.state.account;
        if (this.state.editingNotes !== account.notes) {
            const newAccount = await this.props.quoteToolClient.patchAccount({
                accountId: account.id,
                patch: { notes: this.state.editingNotes }
            });
            this.setState({ isEditingNotes: false, account: newAccount });
        }
        else
            this.setState({ isEditingNotes: false });
    };

    render() {
        const { account } = this.state;
        const quoteToolClient = this.props.quoteToolClient;

        if (!account)
            return <LoadingSpinner isLoading={true} />

        const COMPANY_URL_SEGMENT = getUrlSegmentFromCompanyId(account.defaults.companyId);

        return (
            <>
                <Navbar
                    quoteId={null}
                    subHeading="Account Details"
                    companies={this.context?.companies || []}
                    companyId={this.props.companyId}
                />
                <Container style={{ marginTop: '20px' }}>
                    {account.defaults.companyId === null && <Alert color="danger">This Account Does not Have a Company Associated. Do not use this account</Alert>}
                    <AccountPageHeading
                        name={account.name}
                        account={account}
                        onAccountNameChange={this.handleAccountNameChange}
                        onTaxGroupChange={this.handleTaxGroupChange}
                        quoteToolClient={this.props.quoteToolClient}
                        handlePaymentTermsChange={this.handlePaymentTermsChange}
                        onTaxableChange={this.handleTaxableChange}
                    />
                    <Row className="">
                        {account.defaults.companyId == null  && <Badge color="danger">No Company</Badge>}
                        <Col style={{  "user-select": "none"}}>
                            {this.state.isEditingNotes ? (
                                <Input
                                    type="textarea"
                                    value={this.state.editingNotes}
                                    onChange={(e) => this.setState({editingNotes: e.target.value})}
                                    onBlur={this.handleNotesBlur}
                                    rows="3"
                                />
                            ) : (
                                <div className="d-flex align-items-start">
                                    <em>{account.notes || "No notes available."}</em>
                                    <FontAwesomeIcon
                                        icon={faPencilAlt}
                                        className="ms-2"
                                        style={{cursor: 'pointer'}}
                                        onClick={() => this.setState({isEditingNotes: true})}
                                    />
                                </div>
                            )}
                            Account Number: <div className="short-code"
                                  title={account.formattedShortCode}>{account.formattedShortCode}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3>Quotes</h3>
                            <QuoteTable quoteToolClient={quoteToolClient} filter={{ "account.id": account.id }} companyUrlSegment={COMPANY_URL_SEGMENT} />
                        </Col>
                    </Row>
                    <AccountContactsSection account={account} quoteToolClient={quoteToolClient}
                                            onAccountUpdated={newAccount => this.setState({ account: newAccount })} />
                    <Row>
                        <Col>
                            <AddressSection
                                account={account}
                                quoteToolClient={quoteToolClient}
                                onAccountUpdated={newAccount => this.setState({ account: newAccount })}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3 id="documents">Documents</h3>
                            <DocumentUploader documents={account.documents}
                                              client={quoteToolClient}
                                              parentType={"accounts"}
                                              parentId={account.id}
                                              enableTaxExempt={true}
                                              onDocumentsUpdated={() => this.getAccount()}
                            />
                        </Col>
                    </Row>
                    <TaxIdsAccountSection account={account} quoteToolClient={quoteToolClient}
                                                     onAccountUpdated={newAccount => this.setState({ account: newAccount })} />
                    <CustomerShippingAccountsSection account={account} quoteToolClient={quoteToolClient}
                                                     onAccountUpdated={newAccount => this.setState({ account: newAccount })} />
                    <Row>
                        <Col>
                            <h3>Statistics</h3>
                            <StatisticsCard stats={account.statistics} />
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

// NotSelectableLabel Component
const NotSelectableLabel = () => (
    <span style={{ userSelect: 'none', marginLeft: '5px' }}>
        {/* Non-selectable label */}
    </span>
);

// Account Number Component
const AccountNumber = ({ number }) => (
    number ? (
        <Row>
            <Col>
                <Card>
                    <CardBody>
                        <CardText><strong>Account Number:</strong> <NotSelectableLabel /><code>{number}</code></CardText>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    ) : null
);

// AccountName Component (Optional, can be reused or kept as part of AccountPageHeading)
const AccountName = ({ name, account, onAccountNameChange }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [currentName, setCurrentName] = useState(name || '');

    const toggleEditMode = () => setIsEditing(!isEditing);
    const handleBlur = () => {
        onAccountNameChange(currentName);
        setIsEditing(false);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2>Account - </h2>
                {isEditing ? (
                    <Input
                        type="text"
                        value={currentName}
                        onChange={(e) => setCurrentName(e.target.value)}
                        onBlur={handleBlur}
                        autoFocus
                        style={{ marginLeft: '10px', fontSize: '1.5rem', width: 'auto' }}
                    />
                ) : (
                    <div onClick={() => !name && toggleEditMode()} style={{ marginLeft: '10px', cursor: name ? 'default' : 'pointer', display: 'flex', alignItems: 'center' }}>
                        <h2 style={{ margin: 0 }}>
                            {name || <i style={{ color: 'gray' }}>Add an Account Name</i>}
                        </h2>
                        <FontAwesomeIcon
                            icon={faPencilAlt}
                            onClick={toggleEditMode}
                            style={{ marginLeft: '10px', cursor: 'pointer', fontSize: '1.5rem' }}
                        />
                    </div>
                )}
            </div>
            <Link to={account.defaults.companyId != null ? `/stores/${account.defaults.companyId}/quotes?accountId=${account.id}` : ""}>
                <Button disabled={account.defaults.companyId == null} color="success" outline={true} style={{ marginLeft: '15px' }}>
                    <FontAwesomeIcon icon={faPlus} style={{ color: 'green', marginRight: '5px' }} /> New Quote
                </Button>
            </Link>
        </div>
    );
};

// Statistics Card Component
const StatisticsCard = ({ stats }) => (
    <Card>
        <CardBody>
            <h3 style={{ color: 'grey', fontStyle: 'italic' }}>Coming Soon...</h3>
            <CardText><strong>Total Quotes:</strong> ...</CardText>
            <CardText><strong>Total Revenue:</strong> ...</CardText>
            <CardText><strong>Total Profit:</strong> ...</CardText>
            <CardText><strong>Outstanding Balance:</strong>...</CardText>
        </CardBody>
    </Card>
);

AccountPage.propTypes = {
    quoteToolClient: PropTypes.object.isRequired,
};

export default withRouter(AccountPage);